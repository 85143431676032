import { PropsWithChildren, useEffect } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import InspectionDetailsSideBar from "./components/InspectionDetailsSideBar";
import { InspectionsPageContext } from "../InspectionsPage";

export function InspectionsDetailsPage(props: Readonly<PropsWithChildren>) {
  const params = useParams();
  const id = params["id"] ?? "";
  const context: InspectionsPageContext = useOutletContext();

  useEffect(() => {
    context.setSidemenu(<InspectionDetailsSideBar inspectionId={Number(id)} />);
  }, [id]);

  return (
    <Outlet context={context} />
  );
}

export default InspectionsDetailsPage;

