import { ChartDashboardItem } from "../data/entities/DashboardItem";
import DashboardRepo from "../data/repo/DashboardRepo";
import Dashboard from "../models/Dashboard";
import UserService from "./UserService";

export class DashboardService {
  async getLastInspectionsData(): Promise<Dashboard | undefined> {
    const data = await new DashboardRepo().getLastInspectionsData();
    return data;
  }

  async getInspectionHistory(): Promise<ChartDashboardItem[] | undefined> {
    const history = await new DashboardRepo().getMyInspectionHistory();
    const properties = history?.map((item) => item.properties);    
    const chartData = properties?.map((item) => {
      const itemObj = item as unknown as any;
      return {
        company: itemObj.groupName! as unknown as string,
        value: itemObj.tubingLength! as unknown as number,
        date: itemObj.inspectionDate! as unknown as Date
      };
    });

    const newData = 
      chartData?.map((item) => {
        item.date = new Date(item.date);
        return {
          ...item
        }
      });

    return newData;
  }

}

export default DashboardService;
