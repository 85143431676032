import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useReCaptchaV3 = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async (action: string) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return null;
    }

    const reCaptchaToken = await executeRecaptcha(action);
    if (!reCaptchaToken) {
      console.error("Did not receive a token");
      return null;
    }

    return reCaptchaToken;
  }, [executeRecaptcha]);

  return { handleReCaptchaVerify };
};

export default useReCaptchaV3;
