import { MeasurementConverter } from "../app/measurementConverter";
import InspectionDetails from "../data/entities/InspectionDetailsModel";
import MiniChartDataModel from "../data/entities/MiniChartDataModel";
import { MiniChartRepo } from "../data/repo/MiniChartRepo";
import UserService from "./UserService";
import { Measurement } from "../data/entities/Measurement";
import { MeasurementUnit } from "../data/entities/MeasurementUnit";
import { CompanyAccessModel, EditInspectionModel, InspectionModel, InspectionOvalityViewModel, ScaleModel } from "../data/entities";
import { FileExport, FileExportFactory, FileExportType, FileStructure } from "../models/FileExport";
import ODataResponse from "../data/entities/ODataResponse";
import { InspectionRepo, NoteRepo, ReportRepo } from "../data/repo";
import { NoteContextModel } from "../data/entities/NoteContextModel";
import { CalibrationModel } from "../data/entities/CalibrationModel";
import { NormalizeDataPointsModel } from "../data/entities/NormalizeDataPointsModel";
import NoteService from "./NoteService";

export enum AreaExport {
  inspection, ovality
}
export class InspectionService {

  async getInspection(id: number): Promise<InspectionModel | null> {
    const inspection = await new InspectionRepo().getInspection(id);
    return inspection
  }

  async queryInspections(oDataQuery: string = ""): Promise<ODataResponse<InspectionModel>> {
    const data = await new InspectionRepo().queryInspections(oDataQuery);
    return data;
  }

  async getMyInspections(oDataQuery: string = ""): Promise<ODataResponse<InspectionModel>> {
    const data = await new InspectionRepo().getMyInspections(oDataQuery);

    const defaultSystem = UserService.getDefaultMeasurement();

    // convert tubing length to user's preferred measurement
    if (UserService.getDefaultMeasurement() !== UserService.getPreferredMeasurement()) {
      const converter = new MeasurementConverter();
      const targetSystem = UserService.getPreferredMeasurement();

      data?.value.forEach((inspection) => {
        const mLength = {
          value: inspection.tubing?.length,
          unit: MeasurementUnit.distanceLarge,
          system: defaultSystem
        } as Measurement;

        const convertedLength = converter.convert(mLength, targetSystem);
        inspection.tubing!.length = Math.round(convertedLength?.value!) ?? 0;

      });
    }

    return data;
  }

  async getInspectionDetails(id: string, compareTo?: string): Promise<InspectionDetails | null> {
    const data = await new InspectionRepo().getMyInspectionDetails(id, compareTo);

    const defaultSystem = UserService.getDefaultMeasurement();

    const measurementFactory = (value: number, unit: MeasurementUnit = MeasurementUnit.distanceTiny) => {
      return {
        value: value,
        unit: unit,
        system: defaultSystem
      } as Measurement;
    };

    const scale = await this.getScale(Number(id));

      const mLow = measurementFactory(scale?.flwLowConversion ?? 0);
      const mHigh = measurementFactory(scale?.flwHighConversion ?? 0);

      const converter = new MeasurementConverter();
      const convertedLow = converter.convert(mLow, defaultSystem);
      const convertedHigh = converter.convert(mHigh, defaultSystem);

    if (UserService.getDefaultMeasurement() !== UserService.getPreferredMeasurement()) {
      // Convert chart data to the user's preferred measurement
      const converter = new MeasurementConverter();
      const targetSystem = UserService.getPreferredMeasurement();

      data?.chart.points.forEach((point) => {
        
        const mStart = measurementFactory(point.position, MeasurementUnit.distanceLarge);
        // const mValue = measurementFactory(point.value);

        const convertedStart = converter.convert(mStart, targetSystem);
        // const convertedValue = converter.convert(mValue, targetSystem);

        point.position = Number(convertedStart?.value.toFixed(2));
        // point.value = convertedValue?.value ?? 0;
      });

      data?.chart.notes.forEach((note) => {
        const mPosition = measurementFactory(note.position, MeasurementUnit.distanceLarge);
        const convertedStart = converter.convert(mPosition, targetSystem);
        note.position = Number(convertedStart?.value.toFixed(2));
      
      })

      data?.flwValues.forEach((flw) => {
        const mFlw = measurementFactory(flw.flw, MeasurementUnit.distanceTiny);
        const convertedFlw = converter.convert(mFlw, targetSystem);
        flw.flw = convertedFlw?.value ?? 0;

        const mFlwBaseline = measurementFactory(flw.flwBaseline, MeasurementUnit.distanceTiny);
        const convertedFlwBaseline = converter.convert(mFlwBaseline, targetSystem);
        flw.flwBaseline = convertedFlwBaseline?.value ?? 0;

        const startPosition = measurementFactory(flw.startPosition, MeasurementUnit.distanceLarge);
        const convertedStartPosition = converter.convert(startPosition, targetSystem);
        flw.startPosition = convertedStartPosition?.value ?? 0;
      })
      
      const scale = await this.getScale(Number(id));

      const mLow = measurementFactory(scale?.flwLowConversion ?? 0);
      const mHigh = measurementFactory(scale?.flwHighConversion ?? 0);

      const convertedLow = converter.convert(mLow, targetSystem);
      const convertedHigh = converter.convert(mHigh, targetSystem);
      
      return {
        ...data,
        flwLow: convertedLow?.value ?? 0,
        flwHigh: convertedHigh?.value ?? 0
      } as InspectionDetails;
    }
    
    return {
      ...data,
      flwLow: convertedLow?.value ?? 0,
      flwHigh: convertedHigh?.value ?? 0
    } as InspectionDetails;
  }

  async getInspectionNoteMiniChartData(inspectionId: number, startPosition: number, endPosition: number): Promise<MiniChartDataModel[] | null> {
    let useStart = startPosition;
    let useEnd = endPosition;

    // Set the start and end positions, minimum span
    const minimumSpan = 100;
    const span = endPosition - startPosition;

    if (span < minimumSpan) {
      // Stretch out the start and end points
      useStart = useStart - ((minimumSpan - span) / 2);
      useEnd = useEnd + ((minimumSpan - span) / 2);
      useStart = useStart < 0 ? 0 : useStart; // maximum is unknown to this method
    }

    const data = await new MiniChartRepo().getInspectionNoteMiniChartData(inspectionId, useStart, useEnd);
    return data;
  }

  async getInspectionOvality(inspectionId: number): Promise<InspectionOvalityViewModel | null> {
    const data = await new InspectionRepo().getMyInspectionOvality(inspectionId.toString());

    if (UserService.getDefaultMeasurement() !== UserService.getPreferredMeasurement()) {
      // Convert chart data to the user's preferred measurement
      const converter = new MeasurementConverter();
      const defaultSystem = UserService.getDefaultMeasurement();
      const targetSystem = UserService.getPreferredMeasurement();

      const measurementFactory = (value: number, unit: MeasurementUnit = MeasurementUnit.distanceTiny) => {
        return {
          value: value,
          unit: unit,
          system: defaultSystem
        } as Measurement;
      };

      let nominal = data?.chart.nominal ?? 0;
      data?.chart.points.forEach((point) => {
        const mStart = measurementFactory(point.startPosition, MeasurementUnit.distanceLarge);
        const mDiameterMin = measurementFactory(point.diameterMinimum);
        const mDiameterMax = measurementFactory(point.diameterMaximum);
        const mDiameterAvg = measurementFactory(point.diameterAverage);
        const mNominal = measurementFactory(nominal);

        const convertedStart = converter.convert(mStart, targetSystem);
        const convertedDiameterMin = converter.convert(mDiameterMin, targetSystem);
        const convertedDiameterMax = converter.convert(mDiameterMax, targetSystem);
        const convertedDiameterAvg = converter.convert(mDiameterAvg, targetSystem);
        const convertedNominal = converter.convert(mNominal, targetSystem);

        point.startPosition = convertedStart?.value ?? 0;
        point.diameterMinimum = convertedDiameterMin?.value ?? 0;
        point.diameterMaximum = convertedDiameterMax?.value ?? 0;
        point.diameterAverage = convertedDiameterAvg?.value ?? 0;
        nominal = convertedNominal?.value ?? 0;
      });
    }
    return data;
  }

  

  getFLWExportUrl(inspectionId: number): string {
    return new InspectionRepo().getFLWExportUrl(inspectionId);
  }

  getExportUrls(id: number, type: AreaExport, pdfFileName: string = "", inspection?: InspectionModel): FileExport[] {
    let map: Map<FileExportType, string>;

    switch (type) {
      case AreaExport.inspection:
        map = new InspectionRepo().getMyInspectionExportUrls(id, inspection?.enableFLWExport ?? false);
        break;
      case AreaExport.ovality:
        map = new InspectionRepo().geMyInspectionOvalityExportUrls(id);
        break;
    }

    if (pdfFileName) {
      const pdfUrl = map.get(FileExportType.Report);
      map.set(FileExportType.Report, `${pdfUrl}?filename=${pdfFileName}`);
    }

    let fileExports: FileExport[] = [];
    const entries = Array.from(map.entries());

    entries.forEach(([key, value]) => {
      const baseValue = FileExportFactory.find((item) => item.type === key);
      const fileExport = {
        ...baseValue,
        url: value
      } as FileExport;
      fileExports.push(fileExport);
    });

    return fileExports;
  }

  async exportFile(url: string): Promise<FileStructure | null> {
    const response = await new InspectionRepo().getExportFile(url);
    return response;
  }

  async previewFLW(inspectionId: number): Promise<boolean> {
    const response = await new InspectionRepo().previewFLW(inspectionId);
    return response;
  }

  async createReferencePoint(inspectionId: number, position: number, pointIndex: number): Promise<boolean> {
    const created = await new InspectionRepo().createReferencePoint(inspectionId, position, pointIndex, true);
    return created;
  }

  async addInspection(model: InspectionModel) {
    return await new InspectionRepo().addInspection(model);
  }

  async publishInspection(inspectionId: number): Promise<boolean> {
    const published = await new InspectionRepo().publish(inspectionId);
    return published;
  }

  async deleteInspection(inspectionId: number): Promise<boolean> {
    const deleted = await new InspectionRepo().delete(inspectionId);
    return deleted;
  }

  async getInspectionViewModel(id: number): Promise<EditInspectionModel | null> {
    const inspection = await new InspectionRepo().getInspectionDetailsViewModel(id);
    return inspection;
  }

  async saveInspection(inspection: EditInspectionModel): Promise<boolean> {
    return await new InspectionRepo().saveInspection(inspection);
  }

  async cacheReportInspection(inspectionId: number): Promise<boolean> {
    return await new ReportRepo().cacheReport(inspectionId);
  }

  async unpublish(inspectionId: number): Promise<boolean> {
    return await new InspectionRepo().unpublish(inspectionId);
  }

  async requestApproval(inspectionId: number): Promise<boolean> {
    return await new InspectionRepo().requestApproval(inspectionId);
  }

  async revokeApproval(inspectionId: number): Promise<boolean> {
    return await new InspectionRepo().revokeApproval(inspectionId);
  }

  async getNotesWithContext(inspectionId: number): Promise<NoteContextModel | null> {
    const data = await new NoteRepo().getNotesByInspectionId(inspectionId);
    // const notes = NoteService.convertNotePositions(data ? data.notes : []);
    return data;
    // return {
    //   ...data,
    //   notes: notes ?? []
    // } as NoteContextModel;
  }

  async getCalibrationData(inspectionId: number): Promise<CalibrationModel | null> {
    const data = await new InspectionRepo().getCalibrationData(inspectionId);
    return data;
  }

  async getNormalizeDataPoints(inspectionId: number): Promise<ODataResponse<NormalizeDataPointsModel>> {
    const data = await new InspectionRepo().getNormalizeDataPoints(inspectionId);
    return data;
  }

  async updateCalibrationData(insectionId: number, body: CalibrationModel): Promise<boolean> {
    const data = await new InspectionRepo().updateCalibrationData(insectionId, body);
    return data;
  }

  async getInspectors(id: number): Promise<InspectionModel | null> {
    const response = await new InspectionRepo().getInspectors(id);
    return response;
  }

  async addInspector(id: number, userId: number) {
    return await new InspectionRepo().addInspector(id, userId);
  }

  async removeInspector(id: number, userId: number) {
    return await new InspectionRepo().removeInspector(id, userId);
  }

  async getAccess(id: number): Promise<CompanyAccessModel | null> {
    const response = await new InspectionRepo().getAccess(id);
    return response;
  }

  async addCompanyAccess(id: number, companyId: number) {
    return await new InspectionRepo().addCompanyAccess(id, companyId);
  }

  async removeCompanyAccess(id: number, companyId: number) {
    return await new InspectionRepo().removeCompanyAccess(id, companyId);
  }

  async getScale(inspectionId: number): Promise<ScaleModel | null> {
    return await new InspectionRepo().getScale(inspectionId);
  }

  async saveScale(inspectionId: number, scale: ScaleModel): Promise<boolean> {
    return await new InspectionRepo().saveScale(inspectionId, scale);
  }
}



export default InspectionService;
