import { useParams } from "react-router-dom";
import { InspectionService } from "../../../../services";

export function usePreview() {
  
  
  const params = useParams();
  const inspectionId = params["id"] ?? "";

  const inspectionService = new InspectionService();

  const previewFLW = async () => {
    const url = inspectionService.getFLWExportUrl(parseInt(inspectionId));
    inspectionService.exportFile(url).then((file) => {
      if (!file) return;

      const binaryData = [];
      binaryData.push(file.data);
      const blob = new Blob(binaryData, { type: "text/csv" });
      const objUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = file.name;
      link.target = "_blank";
      link.href = objUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objUrl);
    });
  }
  return {
    previewFLW
  }
}
