import React from "react";
import UserService from "../../services/UserService";
import { MeasurementConverter } from "../../app/measurementConverter";
import { Measurement as DataMeasurement } from "../../data/entities/Measurement";
import { MeasurementUnit } from "../../data/entities/MeasurementUnit";
import SystemOfMeasurement from "../../data/entities/SystemOfMeasurement";

interface MeasurementProps {
  value: number;
  unit: MeasurementUnit;
  showValue?: boolean;
  showUnit?: boolean;
  convert?: boolean;
}

export function Measurement(props: MeasurementProps) {
  const preferredMeasurement = UserService.getPreferredMeasurement();
  const measurement = {
    value: props.value,
    system: preferredMeasurement,
    unit: props.unit,
  } as DataMeasurement;
  const converter = new MeasurementConverter();
  const convertedValue = converter.convert(measurement, preferredMeasurement) as DataMeasurement;
  const formattedValue = converter.toFriendlyString(props.convert ? convertedValue : measurement, props.showValue, props.showUnit);

  return <span className="fw-bold measurement">{formattedValue}</span>;
}

export function millimetersToInches(millimeters: number): number {
  return millimeters / 25.4;
}

export function inchesToMillimeters(inches: number): number {
  return inches * 25.4;
}

Measurement.defaultProps = {
  showValue: true,
  showUnit: true,
  convert: true,
};

export default Measurement;
