import { useState, useEffect } from "react";
import { Icon, LinkLooksLike } from "../../components";
import { FileExport } from "../../models/FileExport";
import { AreaExport, InspectionService } from "../../services";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "react-bootstrap";
import { InspectionModel } from "../../data/entities";

interface FileExporterProps {
  id: number;
  type: AreaExport;
  pdfFileName?: string;
}

export default function FileExporter(props: FileExporterProps) {
  const { id, type, pdfFileName } = props;
  const inspectionService = new InspectionService();
  const [inspection, setInspection] = useState<InspectionModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<FileExport[]>();

  useEffect(() => {

    const fetchInspection = async () => {
      const data = await inspectionService.getInspection(id);
      if(data) setInspection(data);
    }
    fetchInspection();
  }, [id]);

  const onClick = (source: FileExport) => {
    setIsLoading(true);
    inspectionService.exportFile(source.url).then((file) => {
      if (!file) return;

      const binaryData = [];
      binaryData.push(file.data);
      const blob = new Blob(binaryData, { type: source.contentType });
      const objUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = file.name ?? props.pdfFileName ?? `${props.id}.${source.fileExtension}`;
      link.target = "_blank";
      link.href = objUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objUrl);
    }).finally(() => { 
      setIsLoading(false);
    });
  };

  useEffect(() => {
      const filesData = inspectionService.getExportUrls(id, type, pdfFileName, inspection);
      setFiles(filesData);
  }, [inspection]);

  const buttons = files?.map((file) => {
    return (
      <LinkLooksLike
        key={uuidv4()}
        onClick={() => onClick(file)}
        className="text-sm mb-0 me-3"
      >
        <Icon iconName={file.iconClass} className={`me-1`}/>
        {file.label}
      </LinkLooksLike>
    );
  });

  const content = isLoading ? (
    <>
      <strong className="me-3">Downloading...</strong>
      <Spinner
        animation="border"
        variant="dark"
        role="status"
        aria-hidden="true"
        size="sm"
      />
    </>
  ) : (
    <>
      <strong className="me-3">Download:</strong>
      {buttons}
    </>
  );

  return (
    <div className="">
      <hr></hr>
      <div className="px-3 text-sm mb-0 me-3">{content}</div>
    </div>
  );
}
