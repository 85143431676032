import React from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import InspectionCard from "../../models/InspectionCard";

export interface InspectionListItemChartProps {
  model?: InspectionCard;
}

export function InspectionListItemChart(props: Readonly<InspectionListItemChartProps>) {
  const { model } = props;

  return (
    <Chart className="chart">
      <ChartPlotArea></ChartPlotArea>
      <ChartSeries>
        <ChartSeriesItem
          data={model?.chart}
          type="line"
          // eslint-disable-next-line react/style-prop-object
          style="normal"
          field="maxValue"
          categoryField="startPosition"
          markers={{ visible: false }}
          labels={{ visible: false }}
          color="#0D698B"
          width={1}
        />
      </ChartSeries>
      <ChartValueAxis>
        <ChartValueAxisItem
          max={100}
          visible={false}
          majorGridLines={{
            visible: true,
            step: 2,
          }}
        ></ChartValueAxisItem>
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          baseUnitStep={20}
          labels={{
            step: 20,
            font: "9px Arial, Helvetica, Sans Serif",
            color: "#54534A",
            format: "n0",
          }}
          majorGridLines={{
            visible: false,
            step: 20,
          }}
          majorTicks={{
            step: 20,
          }}
          notes={{
            position: "bottom",
            icon: {
              visible: false,
              size: 15,
            },
            line: {
              length: 0,
            },
            label: {
              visible: true,
              color: "#808080",
              position: "outside",
              font: "9px Arial,Helvetica,sans-serif",
            },
            data: [
              { value: 0, label: { text: "core" } },
              { value: model?.chart.length, label: { text: "tail" } },
            ],
          }}
        ></ChartCategoryAxisItem>
      </ChartCategoryAxis>
    </Chart>
  );
}

export default InspectionListItemChart;
